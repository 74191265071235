<template>
  <div class="px-5" id="page-pages">
    <PageActionModal
      ref="pageActionModal"
      :page="page"
    />
    <h5 class="mb-6 ml-2">{{ $t('PAGES.PAGE.PAGES') }}</h5>

    <b-card
      title=""
      class="mb-2"
      body-class="pa-2"
      hide-footer
    >

      <b-row>
        <b-col cols="6" >
          <div class="bottom-alignment">
            <strong>{{ $t('MEMBER.NUM_ROWS') }}:</strong> {{ total_rows }}
          </div>

        </b-col>
        <b-col cols="6">
          <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

            <b-form-select
              class="mr-sm-2 mt-2 align-bottom"
              style="max-width: 100px;"
              :options="[100, 500, 1000]"
              v-model="per_page"
            />

            <b-pagination
              class="mb-0 mt-2 align-bottom"
              style="padding-left: 0px !important;"
              v-model="current_page"
              :total-rows="total_rows"
              :per-page="per_page"
              first-number
              last-number
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        head-variant="light"
        hover
        :fields="page_headers"
        :items="pages"
        :per-page="100"
        :current-page="current_page"
      >
        <template #cell(enabled)="{ item }">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                :checked="Boolean(item.enabled)"
                @click="onClickedEnabled(item)"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>
        <template #cell(su)="{ item }">
          <div class="d-flex align-items-center">
            <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
              <input
                type="checkbox"
                name=""
                :checked="Boolean(item.su)"
                :disabled="!item.enabled"
                @click="onClickedSuperuser(item)"
              />
              <span></span>
            </label>
            <span class="ml-3 cursor-pointer"></span>
          </div>
        </template>
        <template #cell(action)="{ item }">
          <b-button
            type="button"
            class="btn btn-sm"
            @click="showActionModal(item)"
            variant="primary"
          >
            {{ $t('COMMON.MANAGE') }}
          </b-button>
        </template>

      </b-table>

    </b-card>

  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import PageActionModal from '@/view/pages/ml/page/PageActionModal.vue';
import { getPageNames } from '@/core/services/pagenames';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'pages',
  components: {
    PageActionModal
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.loadPageUnions();
  },
  data() {
    return {
      page: null,
      rowsPerPage: [200, 500, 1000, -1],
      page_headers: [
        { label: this.$t('PAGES.PAGE.PAGE'), key: 'trans_name' },
        { label: this.$t('PAGES.PAGE.ON'), key: 'enabled' },
        { label: this.$t('PAGES.PAGE.SUPERADMIN'), key: 'su' },
        { label: '', key: 'action', tdClass: 'text-right' },
      ],
      pages: [],
      current_page: 1,
      per_page: 100,
      total_rows: 0,
    };
  },
  watch: {
    pages(new_val) {
      this.total_rows = new_val.length
    },
  },
  methods: {
    showActionModal(page) {
      this.page = page;
      this.$refs['pageActionModal'].show();
    },

    onClickedSuperuser(item) {
      item.su = !item.su;

      this.$nextTick(() => {
        this.updateSuperuser(item);
      });
    },

    onClickedEnabled(item) {

      item.enabled = !item.enabled;

      this.$nextTick(() => {
        this.updatePage(item);
      });
    },

    updateSuperuser(item) {

      axios
        .put(`/page/${item.id}`, { su: item.su })
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_UPDATE'));
        });
    },

    updatePage(item) {
      if (item.enabled) {
        axios
          .post('/page', { name: item.name })
          .then(res => {
            if (res.status === 201) {
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.PAGE.ACTIVATED'));
              item.id = res.data.id;
              item.su = res.data.su;
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_OCCURED'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
          });
      }
      else {
        axios
          .delete(`/page/${item.id}`)
          .then(res => {
            if (res.status === 204) {
              this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.PAGE.REMOVED'));
              item.su = false;
            }
            else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_OCCURED'));
            }
          })
          .catch(err => {
            console.error(err);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_DELETE'));
          });
      }
    },

    translatePageName(name) {

      const translation = this.$t(getPageNames()[name]);

      if (translation === '' || translation.indexOf('.') >= 0) {
        console.error('page translation not exists: ' + name);
        return name;
      }

      return translation;
    },

    loadPageUnions() {
      axios
        .get(`/page/union`)
        .then(res => {
          if (res.status === 200) {

            let pages = res.data;

            // make a deep copy
            const all_pages = JSON.parse(JSON.stringify(getPageNames()));

            const lookup_pages = {};

            for (const key in all_pages) {
              lookup_pages[key] = { translation: all_pages[key], name: key };
            }

            this.pages = [];

            for (const page of pages) {
              try {
                this.pages.push({
                  name: page.name,
                  id: page.id,
                  enabled: page.enabled,
                  su: page.su,
                  trans_name: this.translatePageName(page.name)
                });

                if (lookup_pages[page.name]) {
                  lookup_pages[page.name].id = page.id;
                }
              }
              catch (err) {
                console.error('should not exist: ' + page.name, err);
              }

            }

            // check pages that doesnt exist
            for (const key in lookup_pages) {
              if (lookup_pages[key].hasOwnProperty('id')) {
                continue;
              }

              // page is not enabled on server
              this.pages.push({
                name: key,
                id: null,
                enabled: false,
                trans_name: this.translatePageName(key)
              });
            }

            // sort it
            this.pages.sort((a, b) => {
              if (a.trans_name < b.trans_name) {
                return -1;
              }

              return 1;
            });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.PAGE.UNABLE_LIST'));
        });
    },

  }
};
</script>

<style lang="scss" scoped>
.bottom-alignment {
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  vertical-align: bottom;
  margin-top: 20px;
  margin-left: 4px;
}

:deep td {
  vertical-align: middle !important;
}
</style>
